
















import { Component, Vue } from 'vue-property-decorator';
import RoomCenterStage from "@/components/room/RoomCenterStage.vue";
import RoomPopInput from "@/components/room/RoomPopInput.vue";

@Component({
  components: {RoomPopInput, RoomCenterStage},
})
export default class BoardData extends Vue {
}
