



























































































































































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class RoomPopInput extends Vue {
  open: boolean = false;

  @Prop({default: true, required: false})
  openable: boolean | null | undefined;

  triggerOpen() {
    if(this.openable) {
      this.open = !this.open;
    }
  }

}
